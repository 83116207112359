import React from 'react'

export default function TagIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512"  xml="preserve" fill="#a9a9a9">
<g>
	<g>
		<path d="M420.64,265.066L203.759,48.761c-3.222-3.216-7.582-5.028-12.133-5.039l-174.31-0.575c-0.017,0-0.04,0-0.058,0    c-4.573,0-8.969,1.818-12.202,5.057C1.801,51.453-0.011,55.866,0,60.462l0.575,174.304c0.011,4.55,1.824,8.911,5.039,12.133    L221.92,463.78c3.233,3.25,7.628,5.068,12.207,5.074c0.006,0,0.012,0,0.012,0c4.579,0,8.969-1.818,12.208-5.057l174.31-174.31    c3.245-3.233,5.063-7.634,5.057-12.213C425.708,272.695,423.884,268.305,420.64,265.066z M234.157,427.175L35.069,227.552    L34.574,77.72l149.832,0.495l199.622,199.087L234.157,427.175z"/>
	</g>
</g>
<g>
	<g>
		<path d="M125.411,125.411c-23.794,0-43.146,19.352-43.146,43.146s19.352,43.146,43.146,43.146    c23.794,0,43.146-19.352,43.146-43.146C168.557,144.764,149.205,125.411,125.411,125.411z M125.411,177.187    c-4.758,0-8.629-3.872-8.629-8.629s3.872-8.629,8.629-8.629c4.758,0,8.629,3.872,8.629,8.629S130.169,177.187,125.411,177.187z"/>
	</g>
</g>
<g>
	<g>
		<path d="M506.926,265.066L290.045,48.761c-6.748-6.737-17.678-6.719-24.403,0.029c-6.731,6.754-6.719,17.678,0.034,24.409    l204.645,204.104L308.23,439.394c-6.742,6.737-6.742,17.667,0,24.403c3.371,3.371,7.784,5.057,12.202,5.057    s8.831-1.686,12.202-5.057l174.31-174.31c3.245-3.233,5.062-7.634,5.057-12.213C511.994,272.689,510.171,268.305,506.926,265.066z    "/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
    )
}
